import { graphql } from "gatsby";
import React from "react";

import CenteredText from "../components/centered-text";
import Layout from "../components/layout";
import SEO from "../components/seo";

const NotFoundPage = ({ data: { avatar, backdrop, logo, site } }) => (
  <Layout avatar={avatar} backdrop={backdrop} site={site}>
    <SEO
      avatar={avatar}
      logo={logo}
      site={site}
      title={"She & Jim: Not Found"}
    />
    <CenteredText>
      <h1>Awww mannn</h1>
      <p>
        You just tried to navigate to a page that doesn&#39;t exist... try using
        one of the navigation links above to go back to a working part of the
        site.
      </p>
    </CenteredText>
  </Layout>
);

export const pageQuery = graphql`
  query ErrorQuery {
    avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
      ...Avatar
    }

    backdrop: file(absolutePath: { regex: "/mountains.png/" }) {
      ...Backdrop
    }

    logo: file(absolutePath: { regex: "/she-and-jim-logo.jpg/" }) {
      ...Logo
    }

    site {
      siteMetadata {
        authors
        social {
          instagram
        }
        siteUrl
        subTitle
        title
      }
    }
  }
`;

export default NotFoundPage;
